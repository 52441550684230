import {
  mdiSortAscending,
  mdiSortDescending,
  mdiAccountMultipleOutline,
  mdiRewind,
  mdiEyeOff,
  mdiAsterisk,
  mdiCheckCircleOutline,
  mdiSignalVariant,
//   mdiCheckboxMarkedCircleOutline,
  mdiPrinter,
  mdiPrinterEye,
  mdiContentSave,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiEye,
  mdiTimerSand,
  mdiArchiveArrowDownOutline,
  mdiAccountGroup,
  mdiElevatorUp,
  mdiFileSendOutline,
  mdiFileEditOutline,
  mdiEmailArrowRightOutline,
  mdiFilterOffOutline,
  mdiDeleteAlertOutline,
  mdiContentSaveOutline,
  mdiSendCheck,
  mdiSendLock,
  mdiTransfer,
  mdiSend,
  mdiMessageProcessingOutline,
  mdiDatabaseClockOutline,
  mdiMessageReplyTextOutline,
  mdiStepBackward2,
//   mdiShareVariant,
  mdiInboxArrowDownOutline,
  mdiShieldAccount,
  mdiContentSaveMoveOutline
} from "@mdi/js";

const data = {
  inboxFilterMenu: [
    { id: 0, userType: "all", command: 'filter_data', title: "menu.unread", slug: "unread", icon: mdiEyeOff },
    { id: 1, userType: "all", command: 'filter_data', title: "menu.pending-delegated", slug: "pending", icon: mdiTimerSand },
    { id: 2, userType: "all", command: 'filter_data', title: "menu.delegated", slug: "delegated", icon: mdiTransfer },
    { id: 3, userType: "all", command: 'filter_data', title: "menu.has-feedback", slug: "has_feedback", icon: mdiMessageReplyTextOutline },
    { id: 4, userType: "all", command: 'filter_data', title: "menu.show-all", slug: "reset", icon: mdiAsterisk }
  ],
  permitInboxFilterMenu: [
    { id: 0, userType: "all", command: 'filter_data', title: "menu.unread", slug: "unread", icon: mdiEyeOff },
    { id: 1, userType: "all", command: 'filter_data', title: "menu.pending", slug: "permit_pending", icon: mdiTimerSand },
    { id: 2, userType: "all", command: 'filter_data', title: "menu.answered", slug: "permit_answered", icon: mdiTransfer },
    { id: 3, userType: "all", command: 'filter_data', title: "menu.show-all", slug: "reset", icon: mdiAsterisk }
  ],
   dispositionFilterMenu: [
    { id: 0, userType: "all", command: 'filter_data', title: "menu.unread", slug: "unread", icon: mdiEyeOff },
    { id: 1, userType: "all", command: 'filter_data', title: "menu.delegated", slug: "delegated", icon: mdiTransfer },
    { id: 2, userType: "all", command: 'filter_data', title: "menu.pending-delegated", slug: "pending", icon: mdiTimerSand },
    { id: 3, userType: "all", command: 'filter_data', title: "menu.feedback-by-me", slug: "done", icon: mdiCheckCircleOutline },
     { id: 1, userType: "all", command: 'filter_data', title: "menu.permit-memo", slug: "permit_disposition", icon: mdiShieldAccount   },
    { id: 1, userType: "all", command: 'filter_data', title: "menu.incoming-letter", slug: "incoming_disposition", icon: mdiInboxArrowDownOutline },
    { id: 4, userType: "all", command: 'filter_data', title: "menu.show-all", slug: "reset", icon: mdiAsterisk }
  ],
  sentFilterMenu: [
    { id: 0, userType: "all", title: "menu.send-success", slug: "send_success", icon: mdiSendCheck },
    { id: 1, userType: "all", title: "menu.revoked", slug: "revoked", icon: mdiSendLock },
    { id: 2, userType: "all", title: "menu.show-all", slug: "reset", icon: mdiAsterisk }
  ],
  inboxMobileSortMenu: [
    { id: 0, title: "menu.previous-page", command: "change_page", slug: -1, icon: mdiArrowLeftThick },
    { id: 1, title: "menu.next-page", command: "change_page", slug: 1, icon: mdiArrowRightThick },
    { id: 2, title: null, command: null, slug: null, icon: null },
    { id: 3, title: "menu.newest", command: "sort_data", slug: "desc", icon: mdiSortDescending },
    { id: 4, title: "menu.oldest", command: "sort_data", slug: "asc", icon: mdiSortAscending },
    { id: 5, title: null, command: null, slug: null, icon: null },
    { id: 6, title: "menu.unread", command: "filter_data", slug: "unread", icon: mdiEyeOff },
    { id: 7, title: "menu.delegated", command: "filter_data", slug: "delegated", icon: mdiTransfer },
    { id: 8, title: "menu.pending-delegated", command: "filter_data", slug: "pending", icon: mdiTimerSand },
    { id: 9, title: "menu.done", command: "filter_data", slug: "done", icon: mdiCheckCircleOutline },
    { id: 10, title: "menu.show-all", command: "filter_data", slug: "reset", icon: mdiAsterisk }
  ],
  sentMobileToolbarMenu: [
    { id: 0, title: "menu.previous-page", command: "change_page", slug: -1, icon: mdiArrowLeftThick },
    { id: 1, title: "menu.next-page", command: "change_page", slug: 1, icon: mdiArrowRightThick },
    { id: 2, title: null, command: null, slug: null, icon: null },
    { id: 3, title: "menu.newest", command: "sort_data", slug: "desc", icon: mdiSortDescending },
    { id: 4, title: "menu.oldest", command: "sort_data", slug: "asc", icon: mdiSortAscending },
  ],
  revisionMobileSortMenu: [
    { id: 0, title: "menu.previous-page", command: "change_page", slug: -1, icon: mdiArrowLeftThick },
    { id: 1, title: "menu.next-page", command: "change_page", slug: 1, icon: mdiArrowRightThick },
    { id: 2, title: null, command: null, slug: null, icon: null },
    { id: 3, title: "menu.newest", command: "sort_data", slug: "desc", icon: mdiSortDescending },
    { id: 4, title: "menu.oldest", command: "sort_data", slug: "asc", icon: mdiSortAscending },
    { id: 5, title: null, command: null, slug: null, icon: null },
    { id: 6, title: "menu.unread", command: "filter_data", slug: "unread", icon: mdiEyeOff },
    { id: 7, title: "menu.pending", command: "filter_data", slug: "pending", icon: mdiTimerSand },
    { id: 8, title: "menu.submitted", command: "filter_data", slug: "submitted", icon: mdiEmailArrowRightOutline },
    { id: 9, title: "menu.show-all", command: "filter_data", slug: "all", icon: mdiFilterOffOutline }
  ],
  inboxMobileActionMenu: [
    { id: 0, title: "menu.mark-as-unread", command: "mark", slug: "unread", icon: mdiEyeOff },
    { id: 1, title: "menu.mark-as-read", command: "mark", slug: "read", icon: mdiEye },
    { id: 2, title: "menu.move-to-archived", command: "move", slug: "archived", icon: mdiArchiveArrowDownOutline }
  ],
  draftFilterMenu: [
    { id: 0, title: "menu.submitted", command: "filter_data", slug: "submitted", icon: mdiEmailArrowRightOutline },
    { id: 1, title: "menu.my-draft", command: "filter_data", slug: "my_draft", icon: mdiFileEditOutline }
  ],
  revisionFilterMenu: [
    { id: 0, title: "menu.unread", command: "filter_data", slug: "unread", icon: mdiEyeOff },
    { id: 1, title: "menu.pending", command: "filter_data", slug: "pending", icon: mdiTimerSand },
    { id: 2, title: "menu.submitted", command: "filter_data", slug: "submitted", icon: mdiEmailArrowRightOutline },
    { id: 3, title: "menu.show-all", command: "filter_data", slug: "all", icon: mdiFilterOffOutline }
  ],
  inboxSortMenu: [
    { id: 0, title: "menu.newest", slug: "desc", icon: mdiSortDescending },
    { id: 1, title: "menu.oldest", slug: "asc", icon: mdiSortAscending }
  ],
  sentToolbarMenu: [
    { id: 0, userType: "all", title: "unread", icon: mdiRewind },
    { id: 1, userType: "all", title: "show-all", icon: mdiAsterisk }
  ],
  sentSortMenu: [
    { id: 0, title: "menu.newest", icon: mdiEyeOff },
    { id: 1, title: "menu.oldest", icon: mdiAccountMultipleOutline }
  ],
  inboxDetailToolbarMenu: [
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 1, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 2, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter }
  ],
  inboxDetailToolbarMenuHasDelegated: [
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
    { id: 1, userType: "all", command: 'broadcast', title: "menu.broadcast", icon: mdiSignalVariant },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 3, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 4, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye }
  ],
  sentDetailToolbarMenu: [
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
    { id: 1, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 3, userType: "all", command: 'revoke', title: "menu.revoke", icon: mdiStepBackward2 },
  ],
  sentDirectDetailToolbarMenu: [
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
    { id: 1, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 3, userType: "all", command: 'revoke', title: "menu.revoke", icon: mdiStepBackward2 },
  ],
  dispositionDetailNDTMenu: [
    //NDT = Not Delegated Toolbar
    { id: 1, userType: "", command: 'disposition', title: "menu.disposition-to-downline", icon: mdiTransfer },
    { id: 2, userType: "all", command: 'feedback', title: "menu.feedback", icon: mdiMessageReplyTextOutline },
    { id: 3, userType: "all", command: 'broadcast', title: "menu.broadcast", icon: mdiSignalVariant },
   //  { id: 3, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 4, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 4, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 5, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye },
  ],
  dispositionDetailHDTMenu: [
    //HDT = Has Delegated Toolbar
    { id: 1, userType: "", command: 'disposition_history', title: "menu.disposition-history", icon: mdiDatabaseClockOutline },
   //  { id: 1, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 2, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 3, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye },
  ],
  dispositionDetailHDTPjpMenu: [
    //HDT = Has Delegated Toolbar
    { id: 1, userType: "", command: 'disposition_history', title: "menu.disposition-history", icon: mdiDatabaseClockOutline },
   //  { id: 1, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 2, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 3, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye },
  ],
  dispositionDetailPermitHDTPjpMenu: [
    //HDT = Has Delegated Toolbar
    { id: 1, userType: "", command: 'disposition_history', title: "menu.disposition-history", icon: mdiDatabaseClockOutline },
   //  { id: 1, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 2, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 3, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye }
  ],
  dispositionDetailKNFMenu: [
    //HDT = Krani No Feedback
    { id: 1, userType: "all", command: 'feedback', title: "menu.feedback", icon: mdiMessageReplyTextOutline },
   //  { id: 1, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 2, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 2, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 3, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye }
  ],
  dispositionDetailKHFMenu: [
    //HDT = Krani Has Feedback
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 1, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 1, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 2, userType: "all", command: 'print_dispo_form', title: "menu.print-disposition", icon: mdiPrinterEye },
  ],
 
  dispositionDetailKHFPjpMenu: [
    //HDT = Krani Has Feedback
   //  { id: 0, userType: "all", command: 'validate', title: "menu.validation", icon: mdiCheckboxMarkedCircleOutline },
   //  { id: 1, userType: "all", command: 'share_link', title: "menu.share-link", icon: mdiShareVariant },
    { id: 1, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
  ],
  createDraftToolbarMenuMobileKrani: [
    { id: 1, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 2, userType: "all", command: 'save', title: "menu.save-as-draft", icon: mdiContentSave }
  ],
  createDraftToolbarMenuMobile: [
    { id: 1, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 2, userType: "karpim", command: 'share_to_revision', title: "menu.submit-to-peers", icon: mdiAccountGroup },
    { id: 3, userType: "all", command: 'save', title: "menu.save-as-draft", icon: mdiContentSave }
  ],
  createDraftToolbarSaveMenu:[
    // { id: 0, userType: "all", command: 'save_only', title: "menu.save only", icon: mdiContentSaveOutline },
    { id: 1, userType: "all", command: 'save_and_close', title: "menu.save and close", icon: mdiContentSaveMoveOutline }
  ],
  createDraftToolbarMenu: [
    { id: 1, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 2, userType: "karpim", command: 'share_to_revision', title: "menu.share-to-revision", icon: mdiAccountGroup }
  ],
  revisionDetailORMenu: [
    //OR = original reviser
    { id: 1, userType: "all", command: 'save', title: "menu.save-only", icon: mdiContentSaveOutline },
    { id: 2, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 3, userType: "karpim", command: 'share_to_revision', title: "menu.share-to-revision", icon: mdiAccountGroup },
    { id: 4, userType: "pjp", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 5, userType: "karpim", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline },
  ],
  revisionDetailTOBODmenu: [
     { id: 1, userType: "pjp", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 2, userType: "all", command: 'save', title: "menu.save-only", icon: mdiContentSaveOutline },
    { id: 3, userType: "pjp", command: 'send', title: "menu.approve-and-send", icon: mdiSend },
    { id: 4, userType: "pjp", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  revisionDetailPjpXmenu: [
    { id: 1, userType: "pjp", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 2, userType: "pjp", command: 'sendx', title: "menu.approve-and-send", icon: mdiSend },
    { id: 3, userType: "pjp", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  revisionDetailTOBODXmenu: [
    { id: 1, userType: "pjp", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 2, userType: "pjp", command: 'submitx', title: "menu.sign-and-submit", icon: mdiSend },
    { id: 3, userType: "pjp", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  revisionDetailPJPToBOMmenu: [
    { id: 1, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 2, userType: "pjp", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline },
    { id: 3, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
  ],
  revisionDetailAproverChanged: [
    { id: 1, userType: "all", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  revisionDetailORNoPeerMenu: [
    //OR = original reviser
    { id: 1, userType: "all", command: 'save', title: "menu.save-only", icon: mdiContentSaveOutline },
    { id: 2, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 3, userType: "karpim", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  revisionDetailORSubmitOnlyMenu: [
    //OR = original reviser
    { id: 1, userType: "all", command: 'submit', title: "menu.submit-to-upline", icon: mdiElevatorUp },
    { id: 2, userType: "karpim", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline },
  ],
  revisionDetailPRMenu: [
    //PR = Peer Reviser
    { id: 1, userType: "all", command: 'save', title: "menu.save-only", icon: mdiContentSaveOutline },
    { id: 2, userType: "all", command: 'submit_as_peer', title: "menu.submit-my-revision", icon: mdiSend },
    { id: 3, userType: "all", command: 'print_preview', title: "menu.print-preview", icon: mdiPrinter },
    { id: 4, userType: "karpim", command: 'terminate_draft', title: "menu.terminate-this-draft", icon: mdiDeleteAlertOutline }
  ],
  draftListSortMenu: [
    { id: 1, title: "menu.my-draft", command: "filter_data", slug: "my_draft", icon: mdiFileEditOutline },
    { id: 2, title: "menu.draft-sent", command: "filter_data", slug: "draft_sent", icon: mdiFileSendOutline },
  ],
  dispositionActionMenu: [
    { id: 1, userType: "", command: 'disposition', title: "menu.disposition-to-downline", icon: mdiTransfer },
    { id: 2, userType: "", command: 'feedback', title: "menu.make-a-feedback", icon: mdiMessageProcessingOutline }
  ],
};
export default data;